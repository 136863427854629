<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="Выберите" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2> Политика конфиденциальности </h2>
				<p>
					Введение
					<br/>
					Понимая важность личной информации для вас, мы будем строго соблюдать законы и правила, требующие принятия
					соответствующих мер безопасности и обеспечения безопасности и контроля вашей личной информации.
					Использование PIX TECHNOLOGY PTE.
					Перед обслуживанием платформы UniLive в рамках LTD, пожалуйста, внимательно прочитайте и полностью поймите
					настоящую Политику конфиденциальности основных функций (далее « настоящую Политику конфиденциальности»),
					чтобы вы могли сделать правильный выбор использования. <br/>
				</p>
				<p>
					Настоящая Политика конфиденциальности применяется только к услугам программного обеспечения UniLive, включая
					веб - сайты, веб - страницы, клиентов и микропрограммы, а также к новым сервисным носителям, которые
					появляются по мере развития технологий.
				</p>
				<p>
					Если у вас есть какие - либо вопросы относительно настоящей Политики конфиденциальности, вы можете связаться с
					нами способами, указанными в настоящей Политике конфиденциальности « Как связаться с нами», и мы будем рады
					ответить на них. Мы также будем время от времени пересматривать настоящую Политику конфиденциальности на
					основе законов и правил и изменений в развитии бизнеса и соответствующим образом информировать вас.
				</p>
				<p>
					Если вы являетесь несовершеннолетним лицом в возрасте 14 лет и младше 18 лет или не обладаете полной
					гражданской правоспособностью по причине состояния психического или психического здоровья, ознакомьтесь с
					настоящей Политикой конфиденциальности под руководством и в сопровождении вашего законного опекуна. Если вы
					несовершеннолетний в возрасте до 14 лет, попросите вашего законного опекуна прочитать и согласиться с
					настоящей Политикой конфиденциальности для вас. Пожалуйста, обратите особое внимание на содержание защиты
					несовершеннолетних.
				</p>
				<h3> Эта Политика конфиденциальности поможет вам понять следующее </h3>
				<p> I. Сбор и использование личной информации </p>
				<p> II. Обмен, передача и раскрытие личной информации </p>
				<p> III. Защита и хранение личной информации </p>
				<p> 4, Ваши права </p>
				<p> V, Защита несовершеннолетних </p>
				<p> vi, Политика конфиденциальности Доступ и обновления </p>
				<p> 7 Как связаться с нами </p>
				<h3> I. Сбор и использование персональных данных </h3>
				<p>
					UniLive предлагает услуги поиска видео, воспроизведения коротких видео, которые не превышают определенного
					периода времени, и если вы не согласны с настоящей политикой конфиденциальности, вы можете перейти в режим
					посетителей, нажав « Не соглашается».
				</p>
				<p>
					Мы также предлагаем вам более широкий ассортимент продуктов и / или услуг. Для реализации функций и / или
					услуг, которые вы выбрали для использования, и соблюдения требований законов и правил, нам необходимо
					собирать, использовать и / или открывать соответствующие необходимые разрешения. Отказ может привести к тому,
					что вы не сможете использовать эту функцию и / или услугу без ущерба для нормального использования других
					несвязанных функций и / или услуг. Вы можете управлять личной информацией и правами через часть 4 настоящей
					Политики конфиденциальности « Ваши права».
				</p>
				<p>
					Если вы согласны с настоящей Политикой конфиденциальности основных функций, мы обрабатываем необходимую личную
					информацию только в том случае, если вы согласны использовать основные функции UniLive. Если вы используете
					другие расширения UniLive, мы запросим ваше согласие на использование конкретных расширений.
				</p>
				<p>
					В определенных сценариях мы также разъясняем вам цель, объем и способ использования соответствующего сбора
					информации путем мгновенного уведомления (всплывающие окна, подсказки страниц) и инструкций по обновлению
					функций. Эти описания являются частью настоящей Политики конфиденциальности и имеют такую же силу, как и
					настоящая Политика конфиденциальности.
				</p>
				<p>
					Как правило, ваши персональные данные собираются и используются или запрашиваются разрешения для реализации
					следующих функций и / или услуг платформы UniLive:
				</p>
				<p>
					1. Услуги по номеру счета
				</p>
				<p>
					Чтобы использовать наш богатый набор функций и / или услуг (отзывы, лайки, коллекции, публикации видео), вам
					необходимо предоставить соответствующую информацию / выполнить соответствующую лицензию для регистрации и
					входа в платформу UniLive.
				</p>
				<p>
					1.1 Электронная почта: если вы используете электронную почту для входа в продукты и услуги UniLive, вам
					необходимо указать адрес электронной почты и пароль в качестве необходимой информации.
				</p>
				<p>
					2. Просмотр контента, воспроизведение и поиск
				</p>
				<p>
					2.1 Просмотр и воспроизведение контента
					Когда вы просматриваете и воспроизводите контент платформы UniLive, мы записываем информацию о вашем
					устройстве (OAID, AndroidID, IDFA) и личные записи в Интернете (просмотр, нажатие, обмен, коллекция), чтобы
					обеспечить вам непрерывный и последовательный опыт использования и гарантировать качество обслуживания.
				</p>
				<p>
					2.1.1 Просмотр, связанный с местоположением: Когда вы используете услуги в том же городе, мы подадим вам
					заявку на разрешение открыть права местоположения для сбора и использования точной информации о
					местоположении. Если вы не санкционируете точную информацию о местоположении, это может повлиять на точность
					позиционирования, и мы будем полагаться на грубую информацию о местоположении (точки доступа WLAN (SSID,
					BSSID), Bluetooth и базовые станции, информацию датчиков, информацию IP - адреса, которая в совокупности с
					точной информацией о местоположении), а также на информацию о местоположении, предоставленную в
					соответствующих функциях и / или услугах (местоположение, самостоятельно заполненное в данных учетной записи,
					ссылки на местоположение, добавленные в загружаемый вами контент), чтобы показать вам контент или услуги,
					связанные с вышеуказанной информацией о местоположении, когда вы используете главную страницу, выбираете,
					находите и фокусируетесь на функциях.
				</p>
				<p>
					2.1.2 Воспроизведение на поперечном экране: Когда вы используете режим поперечного экрана, нам нужно вызвать
					ваш датчик ускорения, гравитации, направления, обнаружить изменение направления экрана устройства с помощью
					информации датчика и переключить поперечный и вертикальный экраны.
				</p>
				<p>
					2.2 Поиск
				</p>
				<p>
					При использовании функции поиска мы собираем информацию о вашем устройстве (OAID, AndroidID, IDFA), инструкции
					поиска (текст, голос или изображение) и информацию журнала. Чтобы обеспечить эффективную поисковую службу,
					информация о ключевых словах временно хранится на вашем локальном устройстве и отображается при нажатии на
					значок поиска, а также вы можете полностью или частично удалить историю поиска.
					Когда вы идентифицируете QR - код, ищете товары или выполняете поиск изображений, мы обратимся к вам с
					просьбой разрешить включение камеры, разрешения на альбом, чтобы вы могли фотографировать или загружать
					изображения через альбом для достижения поиска. При использовании сканирования мы вызовем ваш датчик света,
					чтобы настроить яркость экрана, и вызовем ваш линейный датчик ускорения, чтобы определить направление и угол
					устройства, чтобы помочь вам успешно завершить распознавание содержимого сканирования. Если вы не согласны
					открыть соответствующие права, это не повлияет на использование вами наших основных функций.
				</p>
				<p> 3. Социальное взаимодействие </p>
				<p>
					Мы предлагаем вам широкий спектр социальных взаимодействий. Чтобы помочь вам быстро узнать больше друзей и
					взаимодействовать, мы собираем и используем соответствующую информацию в соответствии со следующими
					инструкциями.
				</p>
				<p>
					3.1 Контент - взаимодействие
				</p>
				<p>
					Когда вы участвуете в интерактивном общении, мы собираем и публично демонстрируем ваши интерактивные записи и
					контент (просмотры, похвалы, комментарии, внимание, коллекция, пересылка, обмен и @ другие пользователи).
				</p>
				<p>
					В тех случаях, когда вы делитесь или получаете информацию о содержимом и деятельности, которыми вы делитесь,
					UniLive должен получить доступ к вашему срезывающему листу, прочитать пароль, код обмена, ссылку, которая
					содержится, для достижения скачка, обмена, получения льгот, услуг связи деятельности, чтобы помочь
					пользователям открыть соответствующий контент и улучшить пользовательский опыт. Мы распознаем содержимое
					пластины обмена только локально и загружаем его на сервер только в том случае, если оно подпадает под
					директиву UniLive. Кроме того, когда вы делитесь или сохраняете видео, изображения, нам нужно прочитать ваш
					фотоальбом.
				</p>
				<p>
					Некоторые устройства могут указывать на то, что приложение читает ваш срез или альбом, но такие системные
					суждения не различают, обрабатывается ли он локально или загружается, и из - за различий в статистических
					стандартах для разных устройств существует вероятность ложных срабатываний. Если у вас есть какие - либо
					вопросы, пожалуйста, свяжитесь с нами.
				</p>
				<p>
					3.2 Уведомление о сообщении
				</p>
				<p>
					Вы знаете и соглашаетесь с тем, что на основе номера мобильного телефона, почтового ящика, который вы
					уполномочены собирать, UniLive может предоставлять вам через SMS, телефон, электронную почту, системные
					уведомления информацию о соответствующем контенте, деятельности, услугах, функциях, которые могут быть вам
					интересны и / или необходимы, чтобы помочь вам в идентификации, проверке безопасности, исследовании
					пользовательского опыта. Если вы не хотите принимать соответствующую push - информацию, вы можете сделать
					соответствующие настройки по пути, указанному в части 4 настоящей Политики конфиденциальности « Ваши права».
				</p>
				<p> 4. Съемка, производство и публикация контента </p>
				<p>
					4.1 Съемки, производство
				</p>
				<p>
					Когда вы используете короткие видеоролики, инструменты прямой трансляции для съемки, создания аудио - видео,
					контента изображений, мы обратимся к вам с просьбой разрешить включение прав камеры для съемки, записи, прав
					на альбом / хранение (включая SD - карту) для реализации загрузки контента альбома и локального синхронного
					хранения для предотвращения потери работы, прав микрофона для аудиозаписи. Пожалуйста, знайте, что мы будем
					загружать только аудио - и видео, контент изображений, которые вы выбрали для загрузки, и не будем загружать
					другую информацию. В то же время, чтобы контролировать свет, регулировать яркость и осуществлять регулировку
					угла изображения съемки, мы вызовем на ваше устройство датчик света, датчик ускорения. При этом вы можете
					предоставить нам полную или частичную лицензию. Если вы не согласны открыть соответствующие права, это не
					повлияет на использование вами наших основных функций.
				</p>
				<p>
					4.2 Публикация
				</p>
				<p>
					Когда вы публично публикуете графический и видеоконтент, мы собираем соответствующий контент и публично
					показываем ваши изображения, прозвища и вышеупомянутую информацию.
				</p>
				<p>
					4.2.1 Быстрая загрузка: Для увеличения скорости загрузки подлежащей публикации работы, обогащения функций
					публикации, оптимизации опыта и экономии трафика, прежде чем нажать кнопку « Публикация» после завершения
					съемки, мы начнем загружать и временно загружать содержимое на наш сервер, если ваше устройство подключено к
					WLAN. Но, пожалуйста, знайте, что эта функция не будет публиковать такие работы заранее и будет представлена
					для публикации только тогда, когда вы нажмете кнопку « Выпустить». Если вы отмените публикацию или не
					загрузили ее по какой - либо другой причине, мы немедленно удалим содержимое. Вы можете отключить эту функцию
					по пути, указанному в части 4 настоящей Политики конфиденциальности « Ваши права».
				</p>
				<p> 5. Индивидуальные рекомендации </p>
				<p>
					5.1 Индивидуальные рекомендации
					Чтобы показать вам, рекомендовать более релевантный контент и предоставить услуги, которые в большей степени
					соответствуют вашим требованиям, мы собираем и используем ваши просмотры, воспроизведение, поисковые записи в
					процессе использования услуг платформы UniLive и в сочетании с информацией об устройствах, собранной в
					соответствии с законом (OAID, AndroidID, IDFA), журнальной информацией и другой информацией, которую вы
					авторизовали, на основе ваших предпочтений, чтобы подтолкнуть вас к страницам, видео, контенту в прямом эфире
					и результатам поиска, которые могут вас заинтересовать, друзьям, которых вы можете знать, коммерческой
					рекламе, услугам, функциям. Если вы разрешаете нам персонализировать рекомендации на главной странице, в
					прямом эфире или на странице торгового центра на основе информации о вашем местоположении, мы также собираем
					информацию о вашем местоположении для персонализации в соответствующих функциях.
				</p>
				<p>
					5.2 Индивидуальные рекомендуемые механизмы работы
				</p>
				<p>
					Персонализированные рекомендации будут основаны на алгоритмических моделях, которые предсказывают ваши
					предпочтения, соответствуют контенту, услугам или другой информации, которая может вас заинтересовать, и
					сортируют эту информацию, которую вы показываете. Мы в режиме реального времени получаем отзывы о
					рекомендуемых моделях в соответствии с вашими личными интернет - записями об использовании платформы UniLive и
					постоянно корректируем и оптимизируем рекомендуемые результаты. Чтобы удовлетворить ваши разнообразные
					потребности, мы внедряем разнообразные рекомендуемые технологии в процесс сортировки, расширяя рекомендуемый
					контент и избегая чрезмерной концентрации одного и того же типа контента.
				</p>
				<p> 6. Обслуживание клиентов </p>
				<p>
					Для предоставления вам услуг клиентов нам может потребоваться, чтобы вы предоставили необходимую личную
					информацию для аутентификации, чтобы гарантировать безопасность вашей учетной записи. Для того, чтобы
					связаться с вами, помочь вам решить проблему или как можно скорее записать обработку и результаты проблемы, мы
					можем собирать следующую информацию: контактные данные (номер телефона / электронный ящик, который вы
					используете, чтобы связаться с нами, или другие контактные данные, которые вы предоставляете нам по своей
					инициативе), ваши сообщения (текст / изображение / аудио - видео / телефонные разговоры с нами или с
					соответствующими лицами), а также другую необходимую информацию (информацию об учетной записи, информацию о
					заказе, другую информацию, которую вы предоставляете для подтверждения соответствующих фактов).
				</p>
				<p>
					В целях предоставления Услуг и обоснованной необходимости повышения их качества мы также можем использовать
					следующую информацию о вас: информацию, предоставленную во время контакта с клиентом, и информацию об ответах
					на анкеты, отправленные нам при участии в опросе.
				</p>
				<p> 7. Адаптация приложений и эксплуатационная безопасность </p>
				<p>
					7.1 Чтобы предоставить вам качественный и надежный контент, услуги, поддерживать нормальную и стабильную
					работу продуктов и функций, защищать вашу безопасность и законные интересы других пользователей или
					общественности от злоупотреблений, лучше предотвращать вредоносные программы, фишинговые сайты,
					киберуязвимости, компьютерные вирусы, кибератаки, риски кибербезопасности, более точно выявлять нарушения
					законов и правил или соответствующих правил платформы UniLive, мы должны собирать, использовать и
					интегрировать информацию о вашем устройстве, журнальную информацию, сенсорную информацию и другую информацию,
					которую вы санкционировали (информацию, собранную от партнеров после получения вашего разрешения в
					соответствии с законом), для оценки учетной записи, безопасной транзакции, проверки личности, выявления
					нарушений и предотвращения нарушений безопасности, В соответствии с законом проводится необходимая
					документация, анализ, Меры по удалению. Только в том случае, если поведение пользователя вызывает условия
					управления ветром (многократное ввод пароля, частый вход в систему в разных местах, учетная запись риска,
					ненормальная сумма платежа, ненормальное поведение вывода), мы получаем доступ к информации о установленном
					вами приложении (имя пакета) или запущенном процессе, общей работе приложения, его использовании и частоте,
					сбое приложения, общей установке и использовании, данных о производительности, источниках приложения и
					загружаем только информацию о названии пакета, которая очень видна в приложении.
				</p>
				<p>
					Информация об устройстве: идентификатор устройства (AndroidID, IDFA, IDFV, UAID (China Mobile UAID, China
					Unicom UAID и China Telecom UAID, только для устройств Android), OAID, Openudid и другие интегрированные
					параметры оборудования и идентификатор устройства, формируемый системной информацией, различные идентификаторы
					будут различаться по срокам действия, возможности перезагрузки пользователем и способам получения и различным
					системам), информация, связанная с сетью (Bluetooth, IP - адрес, сетевой оператор, состояние сети, тип
					доступа, данные о качестве сети), параметры информационного устройства (имя, модель устройства, программное
					обеспечение и аппаратное обеспечение) Операционная система, язык, разрешение).
				</p>
				<p>
					Информация журнала: Операции, использование персональных записей в Интернете (просмотр, воспроизведение,
					поиск, лайки, внимание, коллекция, комментарий, загрузка, обмен, транзакции).
				</p>
				<p>
					Пожалуйста, поймите, что, когда вы переключаете UniLive на фоновую работу устройства, сбор информации,
					связанный с использованием функций, связанных с UniLive, может не быть немедленно прекращен из - за некоторых
					моделей устройства и системных причин, что приводит к кратковременному фоновому сбору информации.
				</p>
				<p>
					7.2 При проведении исследований и статистического анализа в целях повышения качества и эффективности работы мы
					проводим деидентификационную обработку вышеуказанных персональных данных, которая не связана с вашей подлинной
					личностью. Эта деидентифицирующая информация будет использоваться для улучшения контента и макета платформы
					UniLive, поддержки продуктов или услуг для принятия бизнес - решений и улучшения продуктов и услуг, которые мы
					предоставляем на платформе.
				</p>
				<p> 8. Косвенный сбор личной информации от третьих лиц </p>
				<p>
					Мы не будем активно получать вашу личную информацию от третьих лиц. Если для реализации соответствующих
					функций и услуг требуется получение вашей персональной информации от третьих лиц, мы подтверждаем, что третьи
					стороны получили от вас разрешение на раскрытие вашей персональной информации или что третьи стороны
					уполномочены или обязаны раскрыть нам вашу персональную информацию в соответствии с законом. Если мы
					осуществляем деятельность по обработке персональных данных, выходящую за рамки Вашего разрешения на
					предоставление персональных данных третьим лицам, мы получаем Ваше явное согласие.
				</p>
				<p> 9. Файлы cookie и аналогичные технологии </p>
				<p>
					Информационная идентификация файлов cookie и аналогичных устройств является широко используемой технологией в
					Интернете. Когда вы используете наши услуги, мы можем использовать соответствующие технологии для отправки
					одного или нескольких файлов cookie или анонимных идентификаторов (далее именуемых « cookie») на ваше
					устройство для сбора и идентификации информации, когда вы используете услуги платформы UniLive.
					Файлы cookie используются главным образом для обеспечения безопасной и эффективной работы функций продуктов и
					услуг, что позволяет нам подтвердить безопасность ваших счетов и транзакций, а также выявлять аномалии,
					связанные с сбоями и задержками; Помогает вам избежать повторения шагов и процессов заполнения формы и ввода
					содержимого поиска, чтобы получить более легкий опыт использования; В то же время мы также можем использовать
					вышеуказанные технологии, чтобы рекомендовать, показывать и продвигать контент, который вас интересует, и
					оптимизировать ваш выбор и взаимодействие с контентом. Мы обязуемся не использовать файлы cookie для каких -
					либо других целей, кроме тех, которые указаны в настоящей Политике конфиденциальности.
					Вы можете управлять или удалять файлы cookie в соответствии с вашими предпочтениями. Большинство браузеров
					предоставляют пользователям возможность очистить данные кэша браузера, и вы можете очистить данные cookie в
					настройках браузера, а также очистить все файлы cookie, сохраненные в программном обеспечении. Однако после
					очистки могут быть недоступны функции или услуги, предоставляемые нами и зависящие от файлов cookie.
				</p>
				<p> 10. Сторонние технологии SDK и API </p>
				<p>
					Некоторые функции и / или услуги требуют использования технологии SDK (или API) третьих лиц. Пожалуйста,
					знайте, что эти третьи лица
					SDK (или API) может собирать или использовать часть вашей личной информации. Мы проводим строгие проверки
					безопасности соответствующих SDK (или API) и используем технические средства для обеспечения того, чтобы они
					обрабатывали личную информацию в соответствии с настоящей Политикой конфиденциальности и любыми другими
					соответствующими мерами конфиденциальности и безопасности. Кроме того, в отношении компаний, организаций и
					частных лиц, с которыми мы делимся информацией о пользователях, мы также подписываем соглашения, регулирующие
					использование информации о пользователях.
				</p>
				<p> 11. Исключения с согласия </p>
				<p>
					В соответствии с законодательством и нормативными актами для обработки ваших персональных данных не требуется
					вашего согласия в следующих случаях: <br />
					(1) необходимы для заключения или исполнения договора, стороной которого вы являетесь, или для управления
					людскими ресурсами в соответствии с трудовыми нормами и коллективными договорами, заключенными в
					соответствии с законом; br />
					(2) необходимы для выполнения предусмотренных законом обязанностей или обязанностей; " br />
					(3) необходимы для реагирования на чрезвычайную ситуацию в области общественного здравоохранения или для
					защиты жизни, здоровья и имущественной безопасности физических лиц в чрезвычайных ситуациях; " br />
					(4) осуществлять журналистские репортажи, действия по надзору за общественным мнением в общественных
					интересах и обрабатывать вашу личную информацию в разумных пределах; " br />
					(5) Другие случаи, предусмотренные законом, административным регламентом.
				</p>
				<h3> ii. Обмен, передача и раскрытие личной информации </h3>
				<p>
					Мы не передаем ваши персональные данные третьим лицам, за исключением случаев, когда для достижения целей,
					указанных в настоящей Политике конфиденциальности, мы должны передавать ваши персональные данные
					аффилированным компаниям или сторонним партнерам (поставщикам услуг, сетевым операторам, производителям
					устройств и другим партнерам). Мы будем соблюдать принципы законности, законности и необходимости, тщательно
					оценивать цели обработки, возможности обеспечения безопасности наших партнеров и подписывать соответствующие
					соглашения в соответствии с положениями закона. В то же время мы осуществляем строгий надзор и управление
					нашими партнерами и немедленно прекращаем сотрудничество и привлекаем их к юридической ответственности, как
					только будут выявлены нарушения.
				</p>
				<p> Соответствующие сценарии сотрудничества: </p>
				<p> 1. Для реализации соответствующих функций и услуг </p>
				<p> 1.1 Войти в систему и социальные услуги: Для предоставления вам услуг регистрации, входа в систему и обмена
					данными с третьими лицами наши партнеры по регистрации и социальным услугам (почтовый ящик) могут использовать
					информацию о вашем устройстве (OAID, AndroidID, IDFA).</p>
				<p> 1.2 Сторонние продавцы Платформы: Чтобы помочь вам приобрести товары / услуги у сторонних продавцов,
					выполнить бронирование товаров / услуг, показать информацию о скидках, сделанных сторонними продавцами, или
					предоставить вам послепродажное обслуживание, мы передадим информацию о вашем заказе и транзакциях и / или
					идентификационную информацию учетной записи сторонним продавцам Платформы.</p>
				<p> 1.3 Платежные услуги: Для обеспечения безопасности транзакций и завершения оплаты товаров / услуг сторонним
					платежным организациям, с которыми мы сотрудничаем, может потребоваться обрабатывать информацию о вашем
					заказе, информацию об устройстве (OAID, AndroidID, IDFA). </p>
				<p> 1.4 Логистические услуги: Для точного и безопасного завершения доставки и распределения товаров (товаров,
					которые вы покупаете или принимаете участие в мероприятии) сторонние логистические компании неизбежно узнают
					вашу соответствующую информацию о доставке во время отправки и распределения. </p>
				<p> 1.5 Уведомление о сообщении: Чтобы адаптировать функцию push сообщений к используемому вами устройству,
					производители устройств (Huawei, Xiaomi, OPPO, VIVO) будут использовать вашу модель устройства, версию и
					соответствующую информацию об устройстве (OAID, AndroidID, IDFA). </p>
				<p> 1.6 Обслуживание клиентов: Чтобы своевременно обрабатывать ваши жалобы, предложения и другие запросы,
					поставщики услуг клиентов должны использовать вашу учетную запись и соответствующую информацию, чтобы
					своевременно понимать, обрабатывать и реагировать на ваши потребности. При необходимости вам также может
					потребоваться связаться с вами по номеру мобильного телефона или с помощью других средств связи. </p>
				<p> 2. Реклама </p>
				<p>
					Основываясь на нашей бизнес - модели, мы делаем рекламные объявления и размещаем их. Для достижения цели push
					- и - размещения рекламы, а также для оказания нам помощи в оценке, анализе, оптимизации и измерении
					эффективности и результативности размещения рекламы, нам может потребоваться предоставить часть вашей
					информации и данных рекламодателям, поставщикам услуг, сторонним партнерам поставщиков (совместно называемым «
					рекламными партнерами»). Мы обрабатываем данные с помощью технических средств деидентификационного, анонимного
					или зашифрованного алгоритма.
				</p>
				<p>
					2.1 Рекламный push and press: При проведении рекламных push и размещении рекламным партнерам может
					потребоваться использовать деидентифицированную или анонимизированную информацию об устройстве (OAID,
					AndroidID, IDFA), информацию о этикетке или мы используем деидентифицированную или анонимизированную
					информацию об устройстве (OAID, AndroidID, IDFA), информацию о этикетке от наших рекламных партнеров
					(операторов), а также, возможно, в сочетании с другими данными, которые они законно собирают, для оптимизации
					и повышения эффективности доступа к рекламному push и размещению.
				</p>
				<p>
					2.2 Статистика рекламы: Мы можем делиться с нашими рекламными партнерами, предлагающими услуги по
					статистическому анализу и оптимизации рекламы, информацией о вашем устройстве после демаркирования или
					анонимизации (OAID, AndroidID, IDFA), информацией о этикетках, журнальной информацией (нажатие на рекламу,
					просмотр, демонстрация), данными преобразования рекламных эффектов для оценки, анализа, мониторинга и
					измерения эффективности рекламных push - и рекламных услуг, чтобы обеспечить более подходящий для вас
					рекламный контент; Рекламодатели и / или доверенные им поставщики услуг могут потребовать, чтобы мы делились
					частью или всей вышеупомянутой информацией для проведения бизнес - анализа источников пользователей,
					оптимизации стратегий размещения рекламы или улучшения их продуктов и услуг.
				</p>
				<p> 2.3 Сбор личной информации и подсказки в рекламных сценах: Вам может потребоваться по собственной инициативе
					заполнить соответствующую личную информацию (контактные данные, адреса, конкретные типы информации
					отображаются на странице), которая может быть собрана и обработана рекламодателем или его доверенным рекламным
					партнером. </p>
				<p> 3. Обеспечение безопасности и статистического анализа </p>
				<p>
					3.1 Безопасность использования: Для обеспечения безопасности Вашей учетной записи и / или имущества и
					повышения безопасности наших услуг и услуг наших аффилированных лиц и партнеров мы можем обмениваться с
					сторонними партнерами необходимой информацией об устройствах (OAID, AndroidID, IDFA), данными учетной
					записи и журналами, чтобы комплексно оценивать риски, связанные с Вашей учетной записью и транзакциями,
					избегать мошенничества, предотвращать инциденты, связанные с безопасностью, и принимать необходимые меры
					по регистрации, аудиту и утилизации в соответствии с законом.
				</p>
				<p> 3.2 Анализ продукта: Для анализа стабильности и соответствия наших продуктов и / или услуг партнерам по
					аналитической службе может потребоваться использовать журнал обслуживания продукта (информация о сбое
					продукта, вспышек), информацию об устройстве (OAID, AndroidID, IDFA). </p>
				<p> (ii) Перевод </p>
				<p> Если нам необходимо передать личную информацию в результате слияния, разделения, роспуска или объявления
					о банкротстве, мы сообщим вам имя или фамилию и контактную информацию получателя. Мы просим получателя
					продолжать выполнять свои обязательства в соответствии с настоящей Политикой конфиденциальности. Если
					получатель изменяет первоначальную цель обработки и способ обработки, он вновь обратится к вам за
					согласием. </p>
				<p> (3) Открыто </p>
				<p> Мы не раскрываем информацию, которую вы не раскрываете по собственной инициативе, за исключением
					случаев, предусмотренных национальными законами и правилами или с вашего согласия. </p>
				<p> (iv) Исключения для обмена, передачи и раскрытия личной информации </p>
				<p> В соответствии с законами и правилами для обмена, передачи или раскрытия вашей личной информации не
					требуется вашего согласия в следующих случаях: </p>
				<p> 1. необходимо для заключения или исполнения договора, стороной которого вы являетесь, или для
					осуществления управления людскими ресурсами в соответствии с трудовыми нормами и коллективными договорами,
					заключенными в соответствии с законом; </p>
				<p> 2. необходимо для выполнения предусмотренных законом обязанностей или обязанностей; </p>
				<p> 3. необходимы для реагирования на чрезвычайную ситуацию в области общественного здравоохранения или для
					защиты жизни, здоровья и имущественной безопасности физических лиц в чрезвычайных ситуациях; </p>
				<p> 4. осуществлять журналистские репортажи, действия по контролю общественного мнения в общественных
					интересах, обрабатывать личную информацию в разумных пределах; </p>
				<p> 5. обрабатывать в разумных пределах личную информацию, которая была раскрыта лицом самостоятельно или
					иным образом на законных основаниях, в соответствии с положениями Закона о защите личной информации; </p>
				<h3> III. Защита и хранение личной информации </h3>
				<p> (i) Гарантия системы информационной безопасности </p>
				<p>
					Чтобы обеспечить безопасность вашей информации, мы стремимся принять все разумные меры физической,
					электронной и управленческой безопасности (SSL, зашифрованное хранение информации, контроль доступа к
					центру обработки данных), которые соответствуют отраслевым стандартам для защиты вашей информации от
					утечки, повреждения или потери. У нас есть передовая отраслевая система управления безопасностью данных,
					основанная на данных и основанная на жизненном цикле данных, которая повышает безопасность системы UniLive
					от организационного строительства, институционального проектирования, управления персоналом, технологий
					продуктов и оценки воздействия защиты личной информации во многих измерениях.
				</p>
				<p> (ii) Система управления информационной безопасностью </p>
				<p> Мы создали специальные службы безопасности, системы управления безопасностью, процессы безопасности
					данных для обеспечения безопасности ваших персональных данных. Мы применяем строгую систему использования
					данных и доступа к ним, обеспечивая доступ к вашей личной информации только уполномоченным лицам. Мы
					регулярно проводим обучение и подготовку персонала по вопросам безопасности, а также проводим аудит данных
					и технологий в области безопасности. </p>
				<p>
					В то же время мы также создали Центр реагирования на чрезвычайные ситуации безопасности UniLive, который
					поддерживается профессиональными техническими и операционными командами безопасности, чтобы облегчить
					своевременное и эффективное реагирование, устранение различных уязвимостей безопасности и чрезвычайных
					ситуаций, а также взаимодействие с соответствующими департаментами для отслеживания и борьбы с инцидентами
					безопасности. В случае инцидента с безопасностью персональных данных мы будем информировать вас об
					основных обстоятельствах и возможных последствиях инцидента с безопасностью, мерах по удалению, которые мы
					приняли или собираемся принять, рекомендациях, которые вы можете принять для предотвращения и снижения
					риска, а также о мерах по исправлению положения в соответствии с требованиями законодательства. Мы сообщим
					вам об инциденте по почте, письму, телефону, push - уведомлению. Когда трудно сообщить субъекту
					персональной информации один за другим, мы публикуем объявление разумным и эффективным образом. В то же
					время мы также сообщим об устранении инцидентов безопасности, таких как утечка данных, в соответствии с
					требованиями соответствующих надзорных органов.
				</p>
				<p> (iii) Предотвращение рисков безопасности учетной записи </p>
				<p>
					При использовании определенных услуг вы неизбежно раскрываете свою личную информацию (телефон, информацию
					о банковском счете) контрагенту или потенциальному контрагенту. Пожалуйста, защитите свою личную
					информацию должным образом, предоставляя ее другим только в случае необходимости и не доверяя транзакциям
					через продукты или услуги третьих сторон, чтобы информация не была украдена или даже не подвергалась
					мошенничеству в телекоммуникационных сетях.
				</p>
				<p> (4) Хранение информации </p>
				<p> 1. Место хранения </p>
				<p> Компания UniLive хранит персональную информацию, собранную и полученную в ходе операций в Китае, на
					территории Китая в соответствии с законодательством. Если место хранения вашей личной информации
					перемещается с территории Китая за границу, мы будем выполнять его в строгом соответствии с законом или
					получать ваше отдельное согласие. </p>
				<p> 2. Срок хранения </p>
				<p> Как правило, мы храним вашу личную информацию только в течение времени, необходимого для достижения этой
					цели, и в течение срока хранения, установленного законодательством, и после истечения этого срока мы
					удаляем или анонимизируем вашу личную информацию. </p>
				<p> В случае прекращения обслуживания или эксплуатации платформы UniLive мы заблаговременно уведомляем вас
					об уведомлении о прекращении работы в форме вручения или объявления и удаляем или анонимизируем вашу
					личную информацию в течение разумного срока после прекращения обслуживания или эксплуатации. </p>
				<h3> 4, ваши права </h3>
				<p>
					Мы высоко ценим ваши права как субъекта личной информации и делаем все возможное, чтобы защитить ваш
					контроль над вашей личной информацией. После завершения регистрации и входа в учетную запись UniLive вы
					можете воспользоваться своими правами следующим образом (путь реализации прав на связанные продукты может
					быть непоследовательным, в зависимости от обстоятельств), и мы ответим на ваш запрос в соответствии с
					требованиями законодательства. Вы также можете связаться с нами для осуществления своих соответствующих
					прав в порядке, указанном в настоящей Политике конфиденциальности « Как связаться с нами».
				</p>
				<p> (i) Доступ, копирование, передача персональной информации </p>
				<p> Вы можете просматривать, копировать и передавать личную информацию через UniLive: </p>
				<p> (ii) Исправление, дополнение персональной информации </p>
				<p> Если вы обнаружите, что личная информация, которую мы обрабатываем о вас, является неточной или
					неполной, после проверки вашей личности вы можете подать нам заявку на исправление или дополнение
					посредством обратной связи и сообщения об ошибках. </p>
				<p> Как правило, вы можете в любое время исправить и дополнить предоставленную вами информацию, но из
					соображений безопасности и аутентификации (Служба жалоб почтового ящика) некоторая первоначальная
					регистрационная информация и информация о проверке, предоставленная при регистрации, не могут быть
					изменены. </p>
				<p> (3) Удалить личную информацию </p>
				<p> Вы можете удалить часть своей информации по пути, указанному выше в разделе « (2) Исправление,
					добавление персональной информации», или подать заявку на аннулирование учетной записи для удаления всей
					вашей информации. </p>
				<p> Вы также можете обратиться к нам с просьбой удалить личную информацию в следующих случаях: </p>
				<p> 1. Цель обработки достигнута, не может быть достигнута или больше не требуется для достижения цели
					обработки; </p>
				<p> 2. Прекращение предоставления продуктов или услуг или истечение срока хранения; </p>
				<p> 3. Вы отзываете свое согласие; </p>
				<p> 4. Обработка персональных данных в нарушение законодательства или соглашений; </p>
				<p> 5. Другие случаи, предусмотренные законодательством. </p>
				<p> Когда вы удалите информацию из нашего Сервиса, мы можем не удалять соответствующую информацию из системы
					резервного копирования сразу из - за применимых правовых и технических ограничений безопасности, и мы
					прекратим обработку, кроме хранения и принятия необходимых мер безопасности, пока резервное копирование не
					будет очищено или анонимизировано. </p>
				<p> (iv) Отзыв согласия и изменение прав </p>
				<p>
					Пожалуйста, поймите, что для выполнения каждой функции и / или услуги требуется некоторая базовая личная
					информация, и после того, как вы отозвали свое согласие, мы не сможем продолжать предоставлять вам функции
					и / или услуги, соответствующие отзыву вашего согласия, и больше не будем обрабатывать вашу личную
					информацию. Однако ваше решение отозвать свое согласие не влияет на действительность действий по обработке
					персональных данных, которые были предприняты до отзыва на основании личного согласия.
				</p>
				<p> (v) Списание учетной записи </p>
				<p>
					Вы можете аннулировать свой аккаунт, подав заявку. Чтобы убедиться, что соответствующий пользователь или
					субъект, уполномоченный этим пользователем, подает заявку на аннулирование учетной записи, UniLive
					собирает и проверяет идентификационную информацию пользователя и заявителя, делая все возможное для защиты
					прав пользователя. Компания UniLive строго соблюдает законы и правила, касающиеся защиты персональных
					данных, и эта информация используется только для аннулирования учетной записи.
				</p>
				<p> В частности, UniLive указывает, что аннулирование учетной записи является необратимым. Если иное не
					предусмотрено законодательством, мы удаляем или анонимизируем личную информацию о вас после того, как вы
					аннулируете свою учетную запись, и если удаление личной информации технически невозможно, мы прекращаем
					обработку, кроме хранения и принятия необходимых мер безопасности. </p>
				<p> (6) Права на учетную запись умершего </p>
				<p>
					Если пользователь UniLive умирает, а не аннулированная учетная запись может стать учетной записью памяти
					после смерти владельца, UniLive будет продолжать защищать личную информацию пользователя в учетной записи
					памяти. Близкие родственники умершего могут связаться с нами в порядке, указанном в настоящей Политике
					конфиденциальности « Как связаться с нами», и, не нарушая договоренности, достигнутые при жизни умершего,
					и не ущемляя законных интересов других лиц и общественности, они могут после завершения процедуры
					идентификации получить доступ к личной информации умершего, скопировать, исправить и удалить ее для
					осуществления законных и законных прав и интересов, предусмотренных законами и правилами.
				</p>
				<h3> 5, Защита несовершеннолетних </h3>
				<p>
					Мы защищаем персональные данные несовершеннолетних в соответствии с положениями соответствующих
					национальных законов и нормативных актов и собираем, используем, обмениваемся, передаем и раскрываем
					персональные данные несовершеннолетних только в том случае, если это разрешено законом, с явного согласия
					родителей или других опекунов или необходимо для их защиты. Если мы обнаружим, что личная информация о
					несовершеннолетнем была собрана без предварительного согласия родителей или других опекунов, эта
					информация будет удалена. В то же время мы установили строгие правила сбора и использования информации о
					несовершеннолетних для защиты личной информации детей (в возрасте до 14 лет) и подростков (в возрасте до
					14 лет).
					Если вы являетесь опекуном несовершеннолетнего, вы можете связаться с нами в порядке, указанном в
					настоящей Политике конфиденциальности « Как связаться с нами», если у вас есть вопросы относительно личной
					информации несовершеннолетнего, находящегося под вашей опекой.
				</p>
				<h3> vi, Политика конфиденциальности Доступ и обновления </h3>
				<p> (i) Доступ к настоящей Политике конфиденциальности </p>
				<p> 1. При первой регистрации учетной записи UniLive мы проинформируем вас об этой политике
					конфиденциальности и принимаем решение о том, согласны ли вы с ней самостоятельно. </p>
				<p> (ii) Это обновление политики конфиденциальности </p>
				<p> 1.UniLive может в надлежащее время внести изменения в настоящую Политику конфиденциальности, и мы не
					будем ограничивать ваши права в соответствии с настоящей Политикой конфиденциальности без вашего явного
					согласия или соблюдения соответствующих положений законодательства. </p>
				<p> 2. После обновления Политики конфиденциальности UniLive последняя версия будет отображаться в веб -
					письме или другим подходящим способом при входе в систему, чтобы вы могли быть в курсе последних событий в
					этой Политике конфиденциальности. </p>
				<p> 3. Для существенных изменений мы предоставим значительные уведомления (почтовый ящик, частное письмо,
					системные уведомления, подсказки всплывающих окон). </p>
				<h3> 7 Как связаться с нами </h3>
				<p> У нас есть специальная группа по защите персональных данных и ответственные лица. Если у вас есть
					вопросы, жалобы, замечания или предложения по вопросам, связанным с защитой персональных данных, вы можете
					связаться с нами любым из следующих способов: </p>
				<p> 1. Отправить сообщение: unilive.team@gmail.com </p>
				<p> Компания UniLive рассмотрит эти вопросы в кратчайшие сроки и своевременно ответит на них после проверки
					вашей личности. Как правило, мы ответим на ваш запрос в течение 15 рабочих дней. </p>




	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/PrivacyPolicyZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/PrivacyPolicyEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/PrivacyPolicyKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/PrivacyPolicyJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/PrivacyPolicyRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/PrivacyPolicyVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/PrivacyPolicyTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/PrivacyPolicyZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/PrivacyPolicyEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/PrivacyPolicyKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/PrivacyPolicyJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/PrivacyPolicyRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/PrivacyPolicyVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/PrivacyPolicyTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}


h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}
</style>